const iconNames = {
    add: 'ion-plus',
    applyAll: 'ion-ios-world',
    applyAllBelow: 'ion-aandroid-arrow-dropdown-circle',
    arrowDropup: 'ion-android-arrow-dropup',
    arrowDropdown: 'ion-android-arrow-dropdown',
    apps: 'ion-android-apps',
    radioOn: 'ion-android-radio-button-on',
    radioOff: 'ion-android-radio-button-off',
    check: 'ion-checkmark',
    checkCircle: 'ion-checkmark-circled',
    checkbox: 'ion-android-checkbox',
    checkboxBlank: 'ion-android-checkbox-blank',
    checkboxOutlineBlank: 'ion-android-checkbox-outline-blank',
    closeRound: 'ion-close-round',
    clock: 'ion-android-time',
    calendar: 'ion-ios-calendar-outline',
    chevronDown: 'ion-chevron-down',
    chevronLeft: 'ion-chevron-left',
    chevronRight: 'ion-chevron-right',
    chevronUp: 'ion-chevron-up',
    clipboard: 'ion-clipboard',
    close: 'ion-close',
    code: 'ion-code',
    codeWorking: 'ion-code-working',
    delete: 'ion-android-delete',
    dropbox: 'ion-social-dropbox',
    edit: 'ion-edit',
    error: 'ion-android-alert',
    forward: 'ion-forward',
    globe: 'ion-android-globe',
    googleDrive: 'ion-social-googleplus-outline',
    hamburger: 'ion-android-menu',
    help: 'ion-help-circled',
    info: 'ion-information-circled',
    link: 'ion-link',
    list: 'ion-ios-list-outline',
    loading: 'ion-load-c',
    spinner: 'fa fa-circle-o-notch fa-spin fa-fw',
    location: 'ion-ios-location',
    locked: 'ion-locked',
    logout: 'ion-log-out',
    openLink: 'ion-android-open',
    overflowHorizontal: 'ion-android-more-horizontal',
    person: 'ion-android-person',
    remove: 'ion-android-remove',
    search: 'ion-search',
    text: 'ion-document',
    textFormat: 'ion-ios-circle-filled',
    upload: 'ion-android-upload',
    user: 'ion-android-contact',
    warning: 'ion-alert-circled',
    wrench: 'ion-wrench',
    checkAll: 'ion-android-done-all',
    dragHandle: 'ion-android-more-vertical',
    expand: 'ion-arrow-expand',
    download: 'fa fa-download',
    refresh: 'ion-refresh',
    swap: 'ion-arrow-swap',

    plusOutline: 'ion-ios-plus-outline',
    minusOutline: 'ion-ios-minus-outline',

    sort: 'fa fa-sort',
    sortAscending: 'fa fa-sort-asc',
    sortDescending: 'fa fa-sort-desc',
};

export default iconNames;
